import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VukaLogo from '../../assets/fulllogo-removebg-preview.svg';

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const navigate = useNavigate();
  const { login, resetPassword } = useAuth();

  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState(false);

  const validateEmail = () => {
    if (!emailRef.current.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      emailRef.current.setCustomValidity("Please enter a valid email address.");
      setEmailError(true);
    } else {
      emailRef.current.setCustomValidity("");
      setEmailError(false);
    }
    emailRef.current.reportValidity();
  };

  const validatePassword = () => {
    if (passwordRef.current.value.length < 6) {
      passwordRef.current.setCustomValidity("Invalid Password. Please try again");
      setPasswordError(true);
    } else {
      passwordRef.current.setCustomValidity("");
      setPasswordError(false);
    }
    passwordRef.current.reportValidity();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    validateEmail();
    validatePassword();

    if (emailRef.current.checkValidity() && passwordRef.current.checkValidity()) {
      try {
        setLoading(true);
        const userCredential = await login(emailRef.current.value, passwordRef.current.value);
        const user = userCredential.user;
        Swal.fire({
          title: 'Logged in successfully!',
          text: 'Welcome back!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(() => {
          if (user.displayName === 'client') {
            navigate('/client-home');
          } else if (user.displayName === 'provider') {
            navigate('/partner-home');
          }
        }, 2000);
      } catch (error) {
        setPasswordError(true);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(resetEmail);
      setShowResetModal(false);
      Swal.fire({
        title: 'Check your inbox!',
        text: 'A password reset email has been sent.',
        icon: 'success',
        showConfirmButton: false,
        timer:1500
      });
    } catch (error) {
      setResetError(true);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to send password reset email. Please check the email address.',
        icon: 'error',
      });
    }
  };

  return (
    <div className="min-h-screen flex">
      <div className="flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 w-full sm:w-1/2 bg-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 border-b-2 border-gray-400 inline-block">Log In</h2>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm space-y-px">
              {/* Email Input */}
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your Email</label>
                <div className="relative mb-6">
                  <input
                    type="email"
                    ref={emailRef}
                    required
                    onInput={validateEmail}
                    className={`bg-gray-50 border ${emailError ? 'border-red-500 border-2' : 'border-gray-300'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                    placeholder="name@example.com"
                  />
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Your Password</label>
                <div className="relative mb-6">
                  <input
                    type="password"
                    ref={passwordRef}
                    required
                    onInput={validatePassword}
                    className={`bg-gray-50 border ${passwordError ? 'border-red-500 border-2' : 'border-gray-300'} text-gray-900 text-sm rounded-lg block w-full p-2.5`}
                    placeholder="Your Password"
                  />
                </div>
              </div>

              <button disabled={loading} type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-800 hover:bg-purple-950 focus:outline-none">
                Log In
              </button>
            </div>

            <div className="text-sm text-center">
              Don't have an account?{" "}
              <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">Sign Up here</Link>
            </div>
          </form>

          {/* Password Reset Section */}
          <div className="mt-4 text-sm text-center">
            <p>
              Forgot your password?{" "}
              <Link
                to="#"
                onClick={() => setShowResetModal(true)}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Reset it here
              </Link>
            </p>
          </div>

          {/* Password Reset Modal */}
        {showResetModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">

            <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setShowResetModal(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
              <h3 className="text-xl font-bold text-gray-800 mb-4">Reset Password</h3>
              <form onSubmit={handleResetPassword}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                  required
                />
                <button 
                  type="submit" 
                  className="w-full bg-purple-800 hover:bg-purple-950 text-white font-semibold py-3 rounded-lg transition duration-300 ease-in-out"
                >
                  Send Reset Link
                </button>
                
              </form>
              {resetError && (
                <p className="text-red-500 mt-4 text-sm">Error sending reset email.</p>
              )}
            </div>
          </div>
        )}

        </div>
      </div>

      {/* Right Side */}
      <div className="hidden sm:flex w-1/2 bg-gray-800 items-center justify-center p-8 pb-40 xl:pb-56">
          <div className="text-white text-center">
          <img src={VukaLogo} alt="Vuka Logo" className='w-auto h-auto 2xl:ml-5' />
            <h1 className="text-3xl lg:text-4xl xl:text-5xl lg:font-semibold lg:text-wrap  font-bold ">Find the Service you are Looking for.</h1>
            <p className="mt-4 lg:text-xl xl:text-2xl py-3 xl:py-8">Login now and get reliable work done for you.</p>
            <div className=" flex items-center justify-center relative my-8">
              {/* You can replace with real user images */}
              <div className="w-10 h-10 rounded-full   absolute z-30 mr-16 ">
                <img src="https://images.unsplash.com/photo-1493106819501-66d381c466f1?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGZhY2V8ZW58MHx8MHx8fDA%3D" alt="User" className="w-full h-full rounded-full object-cover" />
              </div>
              <div className="w-10 h-10 rounded-full bg-white absolute z-20  ">
              <img src="https://flowbite.com/docs/images/people/profile-picture-2.jpg"alt="User" className="w-full h-full rounded-full object" />
              </div>
              <div className="w-10 h-10 rounded-full bg-white absolute z-10  ml-16">
              <img src="https://flowbite.com/docs/images/people/profile-picture-4.jpg"alt="User" className="w-full h-full rounded-full object" />
              </div>
              <div className="w-10 h-10 rounded-full bg-white absolute z-0  ml-32">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9rN9M5gE8VezuZ9wscDTJJmcRWAdULYGFOw&s"alt="User" className="w-full h-full rounded-full object" />
              </div>
            </div>
            <p className="mt-4 lg:text-xl xl:text-2xl">Find out whose next to you!</p>
          </div>
        </div>
    </div>
  );
}
