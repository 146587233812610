import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import Swal from 'sweetalert2';
import { useData } from '../contexts/DataContext';
import NotFound from '../assets/not-found-removebg-preview.png'


export default function Home() {
  const { sortedCategories, services, isServicesLoaded, categories } = useData();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices, setFilteredServices] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isClick, setIsClick] = useState(true); // Flag to determine click vs. drag
  const [draggedDistance, setDraggedDistance] = useState(0);

  const navigate = useNavigate();

  
   // Filter services based on the search query
   useEffect(() => {
    const filterServices = () => {
      const filtered = {};
      for (const [category, servicesList] of Object.entries(services)) {
        const matchingServices = servicesList.filter(service =>
          service.services.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (matchingServices.length > 0) {
          filtered[category] = matchingServices;
        }
      }
      
      setFilteredServices(filtered);
    };

    filterServices();
  }, [searchQuery, services]);

  if (!isServicesLoaded) {
    return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" role="status">
        <svg aria-hidden="true" className=" text-gray-200 animate-spin dark:text-gray-600 fill-purple-800  w-14 h-14 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span className="sr-only">Loading...</span>
    </div>
    )
  }

  const handleCategoryClick = (category) => {
    navigate(`/display-services/${category}`);
  };

  const handleSearchClick = () => {
    if (searchQuery.trim()) {
      Swal.fire({
        title: 'Searching...',
        text: `Searching for services related to "${searchQuery}"`,
        icon: 'info',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setIsClick(true); // Assume it's a click until proven otherwise
    setDraggedDistance(0); // Reset drag distance
  };

  // Mouse move: Track drag distance to determine whether it was a drag or click
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3; // Adjust scroll speed
    e.currentTarget.scrollLeft = scrollLeft - walk;
    setDraggedDistance(draggedDistance + Math.abs(x - startX)); // Accumulate drag distance
    if (draggedDistance > 5) {
      setIsClick(false); // Consider it a drag if moved more than 5px
    }
  };

  // Mouse up: Finalize drag and prevent click if dragging occurred
  const handleMouseUp = () => {
    setIsDragging(false);
    if (draggedDistance > 5) {
      setIsClick(false); // Confirm it's a drag, not a click
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

   // Handle touch events for scrolling
   const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    setIsClick(true);
    setDraggedDistance(0);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3;
    e.currentTarget.scrollLeft = scrollLeft - walk;
    setDraggedDistance(draggedDistance + Math.abs(x - startX));
    if (draggedDistance > 5) {
      setIsClick(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (draggedDistance > 5) {
      setIsClick(false);
    }
  };

  // Handle service view based on click or drag
  const handleServiceClick = (service) => {
    if (isClick) {
      const encodedService = encodeURIComponent(service);
      navigate(`/service-providers/${encodedService}`);
    }
  };
 
  return (
    <div className="App bg-gray-50 relative">
      <div className='2xl:hidden bg-gray-800 h-52 absolute inset-0'></div>
      <div className='flex flex-col items-center'>
        <h2 className='font md:text-5xl text-4xl mt-16 mb-8 text-white relative z-10 2xl:text-black 2xl:font-semibold '>Looking For a Service!</h2>
        <div className='mx-auto'>
          <div className='mt-8 mb-20'>
            <label htmlFor="hs-trailing-button-add-on-with-icon" className="sr-only">Label</label>
            <div className="relative flex rounded-lg shadow-sm">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search services..."
                id="hs-trailing-button-add-on-with-icon"
                name="hs-trailing-button-add-on-with-icon"
                className="py-4 px-4 sm:px-36 ps-14 block w-full border-gray-300 shadow-sm rounded-full sm:rounded-sm text-lg focus:z-10 focus:border-purple-800 focus:ring-purple-800"
              />
              <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                <svg
                  className="shrink-0 size-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <path d="m21 21-4.3-4.3"></path>
                </svg>
              </div>
              {/* Remove the search button on small screens */}
              <button
                type="button"
                className="hidden sm:inline-flex py-4 px-6 justify-center items-center gap-x-2 text-lg font-semibold rounded-e-md border border-transparent bg-purple-800 text-white hover:bg-purple-950 focus:outline-none focus:bg-purple-800"
                onClick={handleSearchClick}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {categories.length === 0 ? (
        <div className='flex flex-col items-center'>
          <p className='text-center font-bold text-2xl m-5'>No categories found</p>
        </div>
      ) : (
        <div>
          <div className='flex flex-col items-center'>
            <h3 className='font-bold text-2xl m-5'>Select a Category</h3>
          </div>
          <div className='flex flex-wrap justify-center gap-4'>
            {sortedCategories.slice(0, 4).map((category) => (
              <button
                key={category.id}
                onClick={() => handleCategoryClick(category.category)}
                className="btn hover:bg-purple-800 hover:text-white transition:duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 transition-ease-in-out duration-300"
              >
                {category.category}
              </button>
            ))}
            {sortedCategories.length > 4 && (
            <Menu as="div" className="relative">
            <Menu.Button className="btn flex items-center gap-2 hover:bg-purple-800 hover:text-white transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              More <ChevronDownIcon className="w-5 h-5" />
            </Menu.Button>
            
            <Menu.Items className="absolute right-0 mt-2 w-64 origin-top-right bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-600">
              <div className="grid max-w-screen-xl p-4">
                {sortedCategories.slice(4).map((category) => (
                  <Menu.Item key={category.id}>
                    {({ active }) => (
                      <button
                        onClick={() => handleCategoryClick(category.category)}
                        className={`${
                          active ? 'bg-purple-800 text-white' : 'text-gray-900 dark:text-gray-300'
                        } group flex rounded-lg items-center w-full p-3 text-left hover:bg-purple-800 hover:text-white`}
                      >
                        {category.category}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
          
            )}
          </div>
        </div>
      )}

{isServicesLoaded && (
        <div>
          {Object.keys(filteredServices).length === 0 ? (
            <div className='flex flex-col items-center justify-center min-h-screen relative z-10'>
            <img src={NotFound} alt='No Bookings' className='w-3/5 grayscale  opacity-50 lg:w-2/5' />
            <p className='text-3xl sm:text-6xl font-bold text-gray-800 opacity-50'>No Services Found</p>
            </div>
          ) : (
            sortedCategories.map((category) => (
              <div key={category.id} className='mt-7' >
                {filteredServices[category.category]?.length > 0 && (
                  <>
                    <div className='bg-white'>
                      <h4 className='text-left font-bold text-2xl m-5'>{category.category}</h4>
                    </div>
                    <div
                      className='flex overflow-x-hidden space-x-4 px-2'
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      onTouchStart={handleTouchStart}  // Touch events
                      onTouchMove={handleTouchMove}
                      onTouchEnd={handleTouchEnd}
                      style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                    >
                      {filteredServices[category.category].map((service) => (
                        <div
                          key={service.id}
                          className="service-item bg-white rounded overflow-hidden shadow-md min-w-[400px] max-w-xs cursor-pointer hover:bg-gray-100 hover:shadow-lg"
                          onClick={() => handleServiceClick(service.services)}
                          draggable={false} // Prevent image drag
                        >
                          <img
                            className='w-full h-32 sm:h-48 object-cover'
                            src={service.imageUrl}
                            alt={service.services}
                            draggable={false} // Prevent image drag
                          />
                          <div className='m-4'>
                            <p className='font-bold'>{service.services}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))
          )}
        </div>
      )}
        <div className="text-center py-12 bg-gray-100">
          <h2 className="text-3xl font-bold">Our Process</h2>
          <p className="text-lg text-green-500 mt-2 mb-10">Find your service in 3 easy steps!</p>
          
          <div className="flex flex-col md:flex-row justify-center space-y-0 lg:space-y-0 lg:space-x-6 max-w-5xl mx-auto">
            <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 w-full lg:w-1/3">
              <h3 className="text-4xl font-bold">01</h3>
              <h4 className="text-xl font-semibold mt-2 mb-4">Find a service you need</h4>
              <ul className="list-none space-y-2">
                <li className="relative pl-6">
                  <span className="absolute left-0 text-green-500">✔</span> Choose the service you need
                </li>
                <li className="relative pl-6">
                  <span className="absolute left-0 text-green-500">✔</span> Pick the provider you want.
                </li>
              </ul>
            </div>

            <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 w-full lg:w-1/3">
              <h3 className="text-4xl font-bold">02</h3>
              <h4 className="text-xl font-semibold mt-2 mb-4">Schedule tasks conveniently</h4>
              <ul className="list-none space-y-2">
                <li className="relative pl-6">
                  <span className="absolute left-0 text-green-500">✔</span> Enter your location
                </li>
                <li className="relative pl-6">
                  <span className="absolute left-0 text-green-500">✔</span> Pick a date and time that works for you
                </li>
              </ul>
            </div>

            <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 w-full lg:w-1/3">
              <h3 className="text-4xl font-bold">03</h3>
              <h4 className="text-xl font-semibold mt-2 mb-4">Get value for your Money</h4>
              <ul className="list-none space-y-2">
                <li className="relative pl-6">
                  <span className="absolute left-0 text-green-500">✔</span> Get your service done by professionals who have been verified.
                </li>
                <li className="relative pl-6">
                  <span className="absolute left-0 text-green-500">✔</span> Get the results you desire.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center items-center m-7 border-t-2 pt-6 border-gray-600'>
          <h1 className='text-2xl font-bold'>Sign Up and Become a Client or Partner now!</h1>
          <p className='text-gray-500 pb-3 pt-1'>Finding a service provider has never been easier!</p>
          <Link to="/signup" >
          <button className=" font-bold py-3 px-6 rounded-lg border-2 border-gray-600 text-gray-600  hover:text-gray-700 hover:border-gray-700 text-xl transform hover:-translate-y-0.1 hover:translate-x-0.1 hover:scale-105 transition ease-in-out duration-300" >
            Get Started
          </button>
          </Link>
        </div>


    </div>
  );
}
