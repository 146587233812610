import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import { useAuth } from '../../contexts/AuthContext';
import VukaLogo from '../../assets/fulllogo-removebg-preview.svg';

const libraries = ['places'];
const mapContainerStyle = { width: '100%', height: '400px' };
const options = { disableDefaultUI: true, zoomControl: true };
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const IP_INFO_KEY = process.env.REACT_APP_IP_INFO_API_KEY;

export default function Location() {
    const { refreshUserData } = useAuth();
    const [selectedLocation, setSelectedLocation] = useState({
        date: '',
        id: '',
        label: '',
        latLng: '',
        street: '',
    });
    const [coordinates, setCoordinates] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [address, setAddress] = useState('');
    const { state } = useLocation();
    const { userData } = state || {};
    const { id, name, email, phone, gender, profileImageUrl, isProvider } = userData || {};
    const navigate = useNavigate();
    const [registeredDate, setRegisteredDate] = useState('');
    const inputRef = useRef(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const getFormattedDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = date.getHours() % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
        setRegisteredDate(formattedDate);
    };

    useEffect(() => {
        getFormattedDate();
    }, []);

    useEffect(() => {
        if (!isLoaded) return;

        const fetchUserLocation = async () => {
            try {
                const response = await fetch(`https://ipinfo.io/json?token=${IP_INFO_KEY}`);
                if (!response.ok) throw new Error('Failed to fetch location data');
                const locationData = await response.json();
                const [lat, lon] = locationData.loc.split(',');
                const initialCoordinates = { lat: parseFloat(lat), lng: parseFloat(lon) };
                setCoordinates(initialCoordinates);
                setMarkerPosition(initialCoordinates);
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Location Fetch Error',
                    text: `Error fetching location: ${error.message}`,
                });
            }
        };

        fetchUserLocation();
    }, [isLoaded]);

    const handleMapClick = (event) => {
        const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setMarkerPosition(newPosition);
        setSelectedLocation(prev => ({
            ...prev,
            latLng: `Latitude: ${newPosition.lat} Longitude: ${newPosition.lng}`,
        }));
        fetchStreetAddress(newPosition.lat, newPosition.lng);
    };

    const fetchStreetAddress = async (lat, lng) => {
        try {
            if (typeof window.google === 'undefined' || !window.google.maps) {
                throw new Error('Google Maps API is not loaded');
            }

            const geocoder = new window.google.maps.Geocoder();
            const results = await geocoder.geocode({ location: { lat, lng } });

            if (results.results[0]) {
                setSelectedLocation(prev => ({
                    ...prev,
                    street: results.results[0].formatted_address || 'Street address not found',
                }));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Geocoding Error',
                    text: 'No results found for the given coordinates.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Geocoding Error',
                text: `Error fetching street address: ${error.message}`,
            });
        }
    };

    useEffect(() => {
        if (inputRef.current && isLoaded && window.google) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

            const handlePlaceSelect = () => {
                const place = autocomplete.getPlace();
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                setMarkerPosition(location);
                setCoordinates(location);
                setAddress(place.formatted_address);

                // Update selected location and fetch street address
                setSelectedLocation(prev => ({
                    ...prev,
                    latLng: `Latitude: ${location.lat} Longitude: ${location.lng}`,
                    street: place.formatted_address,
                }));
                fetchStreetAddress(location.lat, location.lng); // Fetch the full address
            };

            autocomplete.addListener('place_changed', handlePlaceSelect);
        }
    }, [inputRef, isLoaded]);

    const handleNext = async () => {
        try {
            if (!id) {
                Swal.fire({
                    icon: 'error',
                    title: 'User ID Error',
                    text: 'User ID is not defined',
                });
                return;
            }

            const currentDate = new Date().toLocaleString();
            const locationId = id;

            const userDocRef = doc(db, 'users', id);
            await setDoc(userDocRef, {
                name,
                email,
                id,
                phone,
                gender,
                profileImageUrl,
                isProvider,
                regDate: registeredDate,
                provider_location: isProvider ? selectedLocation.street : "",
                appOwner: 'NotOwner',
                deviceToken: "",
                isVerified: false,
                jobs_done: 0,
                rating: 0,
                services_category: "",
                status: "NotVerified",
                subscribed_services: 0,
                userRole: "user",
                user_country: "Kenya",
            }, { merge: true });

            const locationDocRef = doc(db, 'users', id, 'Locations', id);
            await setDoc(locationDocRef, {
                date: currentDate,
                id: locationId,
                label: selectedLocation.label,
                latLng: selectedLocation.latLng,
                street: selectedLocation.street,
                regDate: registeredDate,
            });
            
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your location has been saved successfully.',
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                if (isProvider) {
                    navigate('/provider-details', { state: { userId: id, userData, selectedLocation } });
                } else {
                    navigate('/client-home');
                    refreshUserData();
                }
            });

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error Handling Next Step',
                text: `Error handling next step: ${error.message}`,
            });
        }
    };

    if (loadError) {
        Swal.fire({
            icon: 'error',
            title: 'Google Maps Load Error',
            text: 'Error loading Google Maps',
        });
        return <div>Error loading Google Maps</div>;
    }

    return (
      <div className="min-h-screen flex">
            <div className="flex flex-col justify-center py-12 px-6 md:px-8 lg:px-10 w-full md:w-2/3 bg-white">
                <div className="2xl:mx-40 p-5">
                    <h2 className="font-bold text-3xl mb-6 text-center text-gray-800">Select Your Location</h2>
                    {coordinates && (
                        <div className="rounded-lg shadow-md overflow-hidden mb-5">
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={coordinates}
                                zoom={15}
                                options={options}
                                onClick={handleMapClick}
                            >
                                {markerPosition && <Marker position={markerPosition} />}
                            </GoogleMap>
                        </div>
                    )}
                    <div className="flex flex-col items-center mb-6">
                        <label htmlFor="search" className="block mb-2 text-sm font-medium text-gray-900 text-center">Search Location</label>
                        <input
                            type="text"
                            ref={inputRef}
                            className="block w-full md:w-1/2 px-4 py-2.5 mb-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-purple-800 focus:border-purple-800 transition duration-300"
                            placeholder="Search for a place..."
                        />
                        <label htmlFor="label" className="block mb-2 text-sm font-medium text-gray-900 text-center">Location</label>
                        <select
                            id="label"
                            value={selectedLocation.label}
                            onChange={(e) => setSelectedLocation((prev) => ({ ...prev, label: e.target.value }))}
                            className="block w-full md:w-1/2 px-4 py-2.5 mb-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:ring-purple-800 focus:border-purple-800 transition duration-300"
                        >
                            <option value="" className="text-gray-900">Select Label</option>
                            <option value="home" className="text-gray-900">Home</option>
                            <option value="office" className="text-gray-900">Office</option>
                            <option value="other" className="text-gray-900">Other</option>
                        </select>
                        <p className="text-gray-600">Selected Address: <span className="font-semibold">{address}</span></p>
                    </div>
                    <button
                        className="w-full bg-purple-800 text-white py-3 rounded-lg hover:bg-purple-950 transition duration-300"
                        onClick={handleNext}
                    >
                        {isProvider ? "Next" : "Sign Up"}
                    </button>
                </div>
            </div>
            <div className="hidden md:flex w-1/3 bg-gray-800 items-center justify-center p-8 ">
          <div className="text-white ml-10 text-center">
          <img src={VukaLogo} alt="Vuka Logo" className='' />
          {/* <h1 className="text-3xl font-bold mb-5 border-b-2 border-white inline-block">Our Process</h1> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span className="text-green-500 inline-block">✔</span>  Find a service you need</h1>
            <div className=''> 
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block"> Pick a Service Provider </h4>
            </div>
            {/* <div className=''>
            <span className="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Pick the provider you want.</h4>
            </div> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span className="text-green-500 inline-block">✔</span> Schedule your task </h1>
            <div className=''>
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block"> Enter your location.</h4>
            </div>
            {/* <div className=''>
            <span className="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Pick a date and time that works for you.</h4>
            </div> */}
            <h1 className="text-xl font-semibold mb-5 lg:text-3xl  inline-block border-opacity-50"><span className="text-green-500 inline-block">✔</span> Get value for your Money</h1>
            <div className=''>
            <h4 className="text-sm md:text-base font-medium mt-2 mb-4 inline-block">Get your service done by professionals</h4>
            </div>
            {/* <div className=''>
            <span className="text-green-500 inline-block">✔</span> 
            <h4 className="text-sm font-medium mt-2 mb-4 inline-block">Get the results you desire.</h4>
            </div> */}
            
          </div>
        </div>
        </div>
    );
}